import React from "react";
// @ts-ignore
import ReactDOM from "react-dom/client";
import "./i18n";
import "./fonts/Manrope-VariableFont_wght.ttf";
import "./font.css";
import "./utils.css";
import "./index.css";
import "leaflet/dist/leaflet.css";
import App from "./containers/App/App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 200000,
    retry: 0,
  },
};

const client = new QueryClient({ defaultOptions: queryConfig });
const container = document.getElementById("root");
// @ts-ignore
const root = ReactDOM.createRoot(container);

root.render(
  <QueryClientProvider client={client}>
    {/*<ReactQueryDevtools initialIsOpen={false} />*/}

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);
